<template>
  <tp-modal
    v-if="supplierReturn"
    name="modal-supplier-return"
    max-width="880px"
  >
    <v-card slot-scope="props">
      <v-card-title>
        <div class="font-weight-bold">
          Chi tiết phiếu trả NCC #{{ supplierReturn.code }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="false"
          color="red lighten-5 red--text rounded-lg text--accent-2 mr-2"
          depressed
          @click="cancelGoodsReceipt(props.payload.index)"
        >
          Hủy
        </v-btn>
        <!-- <v-btn class="mr-1" icon color="primary">
          <v-icon>mdi-printer</v-icon>
        </v-btn> -->
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card flat>
          <v-row class="mx-0">
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Chi nhánh</div>
              <div class="py-1">
                {{ supplierReturn.branch_name }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Nhân viên</div>
              <div class="py-1">
                {{ supplierReturn.created_user_name }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Ngày tạo</div>
              <div class="py-1">{{ supplierReturn.created_at }}</div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Trạng thái</div>
              <div>
                <v-chip
                  class="text-body-2 font-weight-bold px-2"
                  :color="
                    supplierReturn.status === 1 ? 'green' : 'red accent-2'
                  "
                  outlined
                  small
                >
                  {{ supplierReturn.status === 1 ? "Đã trả" : "Bị hủy" }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mt-5 py-4" flat>
          <v-row class="mx-0">
            <v-col class="py-0 px-5">
              <div class="">
                <span class="font-weight-bold">Nhà cung cấp: </span
                >{{ supplierReturn.supplier_code }}
              </div>
            </v-col>
            <v-col class="py-0 px-5">
              <div class="">
                <span class="font-weight-bold">Giá trị trả: </span
                >{{ supplierReturn.value | formatCurrency }}
              </div>
            </v-col>
          </v-row>
        </v-card>

        <card-products-detail v-if="false"></card-products-detail>

        <v-card v-if="supplierReturn.note" class="mt-5" flat>
          <div class="amber lighten-5 text-body-1 px-3 py-2">
            <strong>Ghi chú:</strong> {{ supplierReturn.note }}
          </div>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import CardProductsDetail from "./components/CardProductsDetail";

export default {
  components: {
    CardProductsDetail
  },
  computed: {
    supplierReturn() {
      return this.$store.getters["SUPPLIER_RETURN/supplierReturn"];
    },
    supplierReturnStatusRequest() {
      return this.$store.getters["SUPPLIER_RETURN/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    cancelGoodsReceipt(index) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Hủy phiếu nhập <strong>${this.supplierReturn.code}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục",
            onClickHandler: async () => {
              await this.$store.dispatch("GOODS_RECEIPT/cancelGoodsReceipt", {
                id: this.supplierReturn.id,
                index: index
              });
              if (
                this.supplierReturnStatusRequest.value ===
                "success-cancelGoodsReceipt"
              ) {
                this.$modal.hide({ name: "modal-goods-receipts" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã hủy phiếu nhập"
                  }
                });
              }
            }
          }
        }
      });
    },

    closeModal() {
      this.$modal.hide({ name: "modal-supplier-return" });
    }
  }
};
</script>
